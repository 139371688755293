<template>
  <div class="login-wrapper">
    <Block class="login-block">
      <div v-if="isLoading"><Loader /></div>
      <div v-else>
        <Logo class="login-logo" />
        <div v-if="error">{{ error }}</div>
        <div class="login-form">
          <b-form @submit.prevent="submit">
            <b-form-group label="Адрес портала Битрикс24">
              <b-form-input v-model="portal" placeholder="my.bitrix24.ru" :state="isValid" />
            </b-form-group>
            <button class="btn btn-primary" type="submit" :disabled="!portal || !isValid">Войти</button>
          </b-form>
        </div>
      </div>
    </Block>
    <div class="login-feedback-wrapper">
      <a href="https://ranx.ru/support" target="_blank" class="login-feedback">
        <SpriteIcon icon="support" />
        <span>Обратная связь</span>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Jwt from '@/common/jwt.service'
import Logo from '@/components/ui/Logo'

export default {
  name: 'Login',
  components: {
    Logo
  },
  data () {
    return {
      isLoading: true,
      portal: ''
    }
  },
  computed: {
    ...mapGetters('auth', ['current']),
    ...mapState('auth', ['error']),
    isValid () {
      if (this.portal.length) {
        return /^[a-z0-9-]+\.bitrix24\.ru$/.test(this.portal)
      }

      return null
    }
  },
  async created () {
    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get('token')
    const page = urlParams.get('page')

    if (token) {
      if (!this.current) {
        Jwt.saveToken(token)
      }
      if (page) {
        this.$router.push(page)
      } else {
        this.$router.push({ name: 'home' })
      }
      return
    }

    this.isLoading = false
  },
  methods: {
    async submit () {
      if (!this.portal || !this.isValid) {
        return
      }
      try {
        const clientId = await this.$api.query('bitrix24/auth/clientid', { domain: this.portal })
        window.location.href = 'https://' + this.portal + '/oauth/authorize/?client_id=' + clientId
      } catch (e) {
        window.location.href = 'https://' + this.portal + '/marketplace/detail/ranx.bigame_sale/'
      }
    }
  }
}
</script>

<style>
.login-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 120px);
  padding-bottom: 80px;
}
.login-block {
  width: 410px;
  padding-top: 40px;
  margin-bottom: 20px;
}
.login-logo {
  margin-bottom: 30px;
}
.login-form .form-group {
  margin-bottom: 0;
}
.login-form .btn {
  margin-top: 20px;
}
.login-feedback-wrapper {
  width: 410px;
  display: flex;
  justify-content: flex-end;
}
.login-feedback {
  display: flex;
  align-items: center;
}
.login-feedback span {
  margin-left: 10px;
  border-bottom: 1px dashed;
}
</style>
